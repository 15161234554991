




































































































































import TmallProduct from "@/models/TmallProduct";
import User from "@/models/User";
import { IAccount, IError } from "@/types/interfaces";
import { Component, Vue } from "vue-property-decorator";
import { ActionMethod, mapGetters } from 'vuex';
import { Action, State, Getter } from 'vuex-class';
import Utils from '@/plugins/utils'
const moment = require('moment')

@Component
export default class ProductsPage extends Vue {
  private loading: Boolean = false
  private loadingTmall: Boolean = false
  private disconnectingTmall: Boolean = false
  private connectingShopify: Boolean = false

  @State(state => state.users.currentUser) currentUser: User;
  @State(state => state.shopify.account) account: IAccount;
  @State(state => state.tmallProducts) tmallProducts: {productsToMatch: number, matchedProducts:number, totalProducts:number};

  @Getter('context/getConsumer') consumer: string;
  @Getter('context/getConsumerName') consumerName: string;
  @Getter('shopify/getSignature') signature: string;
  
  @Action('disconnect', { namespace: 'shopify' }) disconnect: ActionMethod;
  @Action('deleteAccount', { namespace: 'tmall' }) deleteTmallAccount: ActionMethod;
  @Action('login', { namespace: 'tmall' }) loginTmall: ActionMethod;
  @Action('connect', { namespace: 'shopify' }) connectShopify: ActionMethod;

  disconnectShopify() {
    this.$confirm.show({
      type: "warning",
      buttonText: "Disconnect",
      title: "Disconnect Shopify",
      text: "Are you sure you want to disconnect your Shopify store? The connection with Tmall will be also terminated.",
      onConfirm: () => {
        this.disconnect().then(result => {
          this.$notify({ type: 'success', text: 'Your store is disconnected' })
          this.$confirm.hide()
          this.$router.push({ name: 'users-auth' })
        }).catch(err => {
          this.$notify({ type: 'error', text: err.message })
          this.$confirm.hide()
        }).finally(() => {
          
        });
      }
    });
  }

  confirmDisconnectTmall() {
    this.$confirm.show({
      type: "warning",
      buttonText: "Disconnect",
      title: "Disconnect Tmall",
      text: "Are you sure you want to disconnect your Tmall store? The synchronized data will be deleted. Your Tmall and Shopify stores will not be impacted.",
      onConfirm: () => {
        this.disconnectingTmall = true
        this.deleteTmallAccount().then((result: any) => {
          this.$notify({
            type: 'success', text: 'Your store is disconnected'
          })
        }).catch(err => {
          this.$notify({ type: 'error', text: err.message })
        }).finally(() => {
          this.disconnectingTmall = false
          this.$confirm.hide()
        })
      }
    });
  }
  
  submitLoginTmall() {
    this.loadingTmall = true
    this.loginTmall().then((result: any) => {
      window.location = result.url
    })
  }

  _moment(value: any) {
    return moment(value)
  }

  connect() {
    this.connectingShopify = true
    this.connectShopify().then(result => {
      this.connectingShopify = false
    }).catch((err: IError) => {
      console.log(err)
    })
  }

  mounted() {
    // if(!this.account || !this.account.providerShopify || !this.account.providerShopify.details || !this.account.providerShopify.details.shop) {
    //   this.$router.push({ name: 'users-auth' })
    // }
  }
}
